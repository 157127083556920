import { useCustomPermissionsQuery, useMeQuery } from '@/graphql';

export const useCustomPermissions = () => {
  const { data: me, loading } = useMeQuery();
  const { data, loading: loadingPermissions } = useCustomPermissionsQuery();

  if (loading || loadingPermissions) {
    return {
      loadingPermissions: true,
    };
  }

  const meRole =
    me?.me?.attributes?.role?.data?.attributes?.name.toLowerCase() ?? '';

  const permissionsData = data?.customPermissions?.data?.[0]?.attributes
    ?.permissions
    ? JSON.parse(
        JSON.stringify(
          data?.customPermissions?.data?.[0]?.attributes?.permissions,
        ),
      )
    : {};

  const firstAvailable =
    Object.keys(permissionsData[meRole]).find(
      (key) => permissionsData[meRole][key] === true,
    ) ?? 'index';

  const isOwner = meRole === 'owner';

  return {
    loadingPermissions: loading || loadingPermissions,
    isOwner,
    firstAvailable,
    hasDashboardPermission: !!permissionsData[meRole].dashboard,
    hasCrmPermission: !!permissionsData[meRole].crm,
    hasAccountingPermission: !!permissionsData[meRole].accounting,
    hasContractsPermission: !!permissionsData[meRole].contracts,
    hasHrPermission: !!permissionsData[meRole].hr,
    hasSchedulingPermission: !!permissionsData[meRole].employeeScheduling,
    hasInventoryPermission: !!permissionsData[meRole].inventory,
    hasMarketingPermission: !!permissionsData[meRole].marketing,
    hasReportsPermission: !!permissionsData[meRole].reports,
    hasSellingPermission: !!permissionsData[meRole].selling,
    hasTaskManagementPermission: !!permissionsData[meRole].tasks,
    hasWebsiteEditorPermission: !!permissionsData[meRole].websiteEditor,
    hasLocationPermission: !!permissionsData[meRole].location,
    hasIntegrationPermission: !!permissionsData[meRole].integrations,
    hasSettingPermission: !!permissionsData[meRole].settings,
    hasAllowAddOrRemoveOptionsInProductSelect:
      !!permissionsData[meRole].allowAddOrRemoveOptionsInProductSelect,
    hasProductItemCostVisible: !!permissionsData[meRole].productItemCostVisible,
    hasProductItemRelationVisible:
      !!permissionsData[meRole].productItemRelationVisible,
  };
};
