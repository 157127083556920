import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { ActivityTypeSelect, DatePicker, EmployeeSelect } from '@ui/form';

export const ActivityAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'type'}
      label={'Activity Type'}
      defaultOpen={!!values?.type?.length}
      withDivider
    >
      <ActivityTypeSelect
        mode={'multiple'}
        placeholder={'Filter activities by Type'}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Due Date'}
      name={'due_date'}
      defaultOpen={!!values?.due_date}
      withDivider
    >
      <DatePicker style={{ width: '100%', padding: '11px 15px' }} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Owner'}
      name={'owner'}
      defaultOpen={!!values?.owner}
      withDivider
    >
      <EmployeeSelect
        shouldApplyDefaultValue={false}
        placeholder={'Filter activities by Activity Owner'}
      />
    </ExpandableFormItem>
  </>
);
