import { formatDateAndTime } from '@helpers/formatDateAndTime';
import dayjs from 'dayjs';

export interface PeriodType {
  gte?: Date;
  lte?: Date;
}

export const DEFAULT_PERIOD: PeriodType = {
  lte: dayjs().add(1, 'day').toDate(),
  gte: dayjs().subtract(6, 'day').toDate(),
};

export const formatDateRange = (period: PeriodType | undefined): string => {
  if (!period) return '';

  const { gte, lte } = period;

  if (!gte || !lte) return '';

  const formattedGte = dayjs(gte).format('DD MMM, YY');
  const formattedLte = dayjs(lte).format('DD MMM, YY');

  return `: ${formattedGte} - ${formattedLte}`;
};

export const handleCSVHeaders = (extraColumns: string[]) => {
  const headersArr = ['CUSTOMER', 'TOTAL SPENT', 'ORDERS NUMBER'];

  if (extraColumns.find((el) => el === 'biggestOrderValue')) {
    headersArr.push('BIGGEST ORDER PRICE');
  }

  if (extraColumns.find((el) => el === 'totalItemsPurchased')) {
    headersArr.push('ITEMS PURCHASED');
  }

  if (extraColumns.find((el) => el === 'birthdayDate')) {
    headersArr.push('BIRTH DATE');
  }

  if (extraColumns.find((el) => el === 'leadSource')) {
    headersArr.push('LEAD SOURCE');
  }

  if (extraColumns.find((el) => el === 'lastPurchaseDate')) {
    headersArr.push('LAST PURCHASE DATE');
  }

  if (
    extraColumns.find((el) => el === 'amountSpentInPeriod') ||
    extraColumns.find((el) => el === 'itemsPurchasedInPeriod')
  ) {
    headersArr.push('PERIOD');
  }

  if (extraColumns.find((el) => el === 'amountSpentInPeriod')) {
    headersArr.push('SPENT IN PERIOD');
  }

  if (extraColumns.find((el) => el === 'itemsPurchasedInPeriod')) {
    headersArr.push('PURCHASED IN PERIOD');
  }
  return headersArr;
};

export function calculateOrderAverageGrossMargin(
  attributes: OrderFragment['attributes'],
): number | null {
  const grossMargins = (attributes?.salesItemReports?.data ?? [])
    .map((report) => report.attributes?.grossMargin)
    .filter(
      (grossMargin): grossMargin is number =>
        typeof grossMargin === 'number' && grossMargin > 0,
    );

  return grossMargins.length > 0
    ? grossMargins.reduce((acc, margin) => acc + margin, 0) /
        grossMargins.length
    : null;
}

export function memoStatus(
  data: ProductInventoryItemEventFragment['attributes'],
): string {
  let status = '-';

  if (data) {
    const { expiryDate, change, remainingQuantity } = data;

    const isDateExpired = expiryDate && new Date(expiryDate) < new Date();
    const isSold = Number(remainingQuantity) === 0;
    const isOnSale =
      Number(remainingQuantity) === Number(change) && !isDateExpired;
    const isPartialSold =
      Number(remainingQuantity) > 0 &&
      Number(remainingQuantity) < Number(change) &&
      !isDateExpired;
    const isPartialExpired =
      Number(remainingQuantity) > 0 &&
      Number(remainingQuantity) < Number(change) &&
      isDateExpired;
    const isFullyExpired =
      Number(remainingQuantity) === Number(change) && isDateExpired;

    if (isSold) {
      status = 'Sold';
    } else if (isOnSale) {
      status = 'On Sale';
    } else if (isPartialSold) {
      status = 'Partially Sold';
    } else if (isPartialExpired) {
      status = 'Partially Expired';
    } else if (isFullyExpired) {
      status = 'Expired';
    }

    return status;
  } else {
    return status;
  }
}

export function memoOutStatus(
  data: ProductOrderItemFragment['attributes'],
): string {
  let status = '-';

  if (data) {
    const { order } = data;
    const orderCreationDate = order?.data?.attributes?.createdAt;
    const createdAtDate = orderCreationDate
      ? new Date(orderCreationDate)
      : null;
    const memoDays = order?.data?.attributes?.memo
      ? Number(order?.data?.attributes?.memo)
      : 0;
    const memoExpiryDate = createdAtDate
      ? new Date(createdAtDate.setDate(createdAtDate.getDate() + memoDays))
      : null;
    const amountPaid = order?.data?.attributes?.paidSummary;
    const orderTotal = order?.data?.attributes?.total;

    const isDateExpired = !!(memoExpiryDate && memoExpiryDate < new Date());
    const isExpired = Number(amountPaid) === 0 && isDateExpired;
    const isPartiallyPaid =
      Number(amountPaid) > 0 &&
      Number(amountPaid) < Number(orderTotal) &&
      isDateExpired;
    const isPaid = Number(amountPaid) >= Number(orderTotal) && isDateExpired;

    if (!isDateExpired) {
      status = 'On Sale';
    } else if (isExpired) {
      status = 'Expired';
    } else if (isPartiallyPaid) {
      status = 'Partially Paid';
    } else if (isPaid) {
      status = 'Paid';
    }

    return status;
  } else {
    return status;
  }
}

export const memoOutDefaultFilters = {
  memo: {
    ne: null,
  },
  status: {
    ne: 'draft',
  },
};

export const handleExtraCsvColumns = (
  contact: ContactsDataByPeriodFragment,
  extraColumns: string[],
  period: PeriodType,
  index: number,
): string[] => {
  const itemArr = [
    contact?.attributes?.fullName ?? '',
    contact?.attributes?.calculatedSpent?.toString() ?? '',
    contact?.attributes?.numberOfOrders?.toString() ?? '',
  ];

  const lastPurchaseDateString = contact?.attributes?.lastPurchaseDate
    ? dayjs(contact?.attributes?.lastPurchaseDate)?.format('MM/DD/YYYY')
    : '';
  const birthdayDateString = contact?.attributes?.birthdayDate
    ? dayjs(contact?.attributes?.birthdayDate)?.format('MM/DD/YYYY')
    : '';

  if (extraColumns.find((el) => el === 'biggestOrderValue')) {
    itemArr.push(contact?.attributes?.biggestOrderValue?.toString() ?? '');
  }

  if (extraColumns.find((el) => el === 'totalItemsPurchased')) {
    itemArr.push(contact?.attributes?.totalItemsPurchased?.toString() ?? '');
  }

  if (extraColumns.find((el) => el === 'birthdayDate')) {
    birthdayDateString;
  }

  if (extraColumns.find((el) => el === 'leadSource')) {
    itemArr.push(contact?.attributes?.leadSource ?? '');
  }

  if (extraColumns.find((el) => el === 'lastPurchaseDate')) {
    itemArr.push(lastPurchaseDateString);
  }

  if (
    extraColumns.find((el) => el === 'amountSpentInPeriod') ||
    extraColumns.find((el) => el === 'itemsPurchasedInPeriod')
  ) {
    if (index === 0) {
      const startDate = period?.gte
        ? dayjs(period.gte).format('MM/DD/YYYY')
        : '';
      itemArr.push(`from: ${startDate}`);
    } else if (index === 1) {
      const endDate = period?.lte ? dayjs(period.lte).format('MM/DD/YYYY') : '';
      itemArr.push(`to: ${endDate}`);
    } else {
      itemArr.push('');
    }
  }

  if (extraColumns.find((el) => el === 'amountSpentInPeriod')) {
    itemArr.push(contact?.attributes?.amountSpentInPeriod?.toString() ?? '');
  }

  if (extraColumns.find((el) => el === 'itemsPurchasedInPeriod')) {
    itemArr.push(contact?.attributes?.itemsPurchasedInPeriod?.toString() ?? '');
  }

  return itemArr;
};

export const splitArray = (
  arr:
    | ContactsDataByPeriodFragment[]
    | OrderFragment[]
    | ProductReportFragment[]
    | OrderTaxReportFragment[]
    | MarketingCustomersReportFragment[]
    | ProductOrderItemWithCustomerFragment[],
  firstIterationSize: number,
  restSize: number,
) => {
  const result = Array.from(
    { length: Math.ceil(arr?.length / restSize) },
    (_, index) => {
      if (index === 0) {
        return arr.slice(
          index * firstIterationSize,
          (index + 1) * firstIterationSize,
        );
      } else {
        return arr.slice((index - 1) * restSize, index * restSize);
      }
    },
  );
  return result;
};

export const handleInventoryColumns = (product: ProductReportFragment) => {
  return [
    BigInt(product?.attributes?.barcode ?? 0n).toString() ?? '',
    product?.attributes?.name ?? '',
    product?.attributes?.defaultPrice?.toString() ?? '',
    product?.attributes?.totalQuantitySold?.toString() ?? '',
    product?.attributes?.quantity?.toString() ?? '',
    product?.attributes?.rentableData?.data?.attributes?.enabled ? 'Yes' : 'No',
    product?.attributes?.numberLocationsPresented?.toString() ?? '',
    product?.attributes?.productInventoryItems?.data
      ?.map(
        (item) => item?.attributes?.sublocation?.data?.attributes?.name ?? '',
      )
      .filter(Boolean)
      .join('; '),
  ];
};

export const handleMemoColumns = (
  product: ProductInventoryItemEventFragment,
) => {
  return [
    product?.attributes?.order?.data?.attributes?.orderId ?? '',
    product?.attributes?.productInventoryItem?.data?.attributes?.product?.data
      ?.attributes?.name ?? '',
    product?.attributes?.productInventoryItem?.data?.attributes
      ?.businessLocation?.data?.attributes?.name ?? '',
    product?.attributes?.productInventoryItem?.data?.attributes?.product?.data
      ?.attributes?.SKU ?? '',
    product?.attributes?.itemVendor?.data?.attributes?.name ??
      product?.attributes?.itemContactVendor?.data?.attributes?.fullName ??
      '',
    `${product?.attributes?.remainingQuantity}/${Number(
      product?.attributes?.change,
    )}` ?? '',
    formatDateAndTime(product?.attributes?.receiveDate as Date)
      ?.split(',')
      .join('') ?? '',
    formatDateAndTime(product?.attributes?.expiryDate as Date)
      ?.split(',')
      .join('') ?? '',
    memoStatus(product?.attributes) ?? '',
  ];
};

export const handleMemoOutColumns = (
  product: ProductOrderItemWithCustomerFragment,
) => {
  return [
    product?.attributes?.order?.data?.attributes?.orderId ?? '',
    product?.attributes?.product?.data?.attributes?.product?.data?.attributes
      ?.name ?? '',
    product?.attributes?.order?.data?.attributes?.contact?.data?.attributes
      ?.fullName ??
      product?.attributes?.order?.data?.attributes?.company?.data?.attributes
        ?.name ??
      '',
    product?.attributes?.product?.data?.attributes?.product?.data?.attributes
      ?.SKU ?? '',
    String(product?.attributes?.price) ?? '',
    `${product?.attributes?.quantity}/${product?.attributes?.quantity}` ?? '',
    formatDateAndTime(product?.attributes?.order?.data?.attributes?.createdAt)
      ?.split(',')
      .join('') ?? '',
    formatDateAndTime(product?.attributes?.order?.data?.attributes?.expiryDate)
      ?.split(',')
      .join('') ?? '',
    memoOutStatus(product?.attributes) ?? '',
  ];
};

export const handleTaxReportColumns = (order: OrderTaxReportFragment) => {
  return [
    order?.attributes?.orderId ?? '',
    order?.attributes?.total?.toString() ?? '',
    order?.attributes?.businessLocation?.data?.attributes?.name ?? '',
    order?.attributes?.taxPortion?.toString() ?? '',
  ];
};
export const handleMarketingReportColumns = (
  report: MarketingCustomersReportFragment,
) => {
  const customerFullName =
    report?.attributes?.enrolledContact?.data?.attributes?.contact?.data
      ?.attributes?.fullName ??
    report?.attributes?.enrolledLead?.data?.attributes?.lead?.data?.attributes
      ?.fullName ??
    '';
  const campaignName =
    report?.attributes?.enrolledContact?.data?.attributes?.contact?.data
      ?.attributes?.fullName ??
    report?.attributes?.enrolledLead?.data?.attributes?.lead?.data?.attributes
      ?.fullName ??
    '';

  return [
    customerFullName,
    campaignName,
    report?.attributes?.SMSsent?.toString() ?? '',
    report?.attributes?.EMAILsent?.toString() ?? '',
  ];
};
