import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import {
  BusinessLocationsSelect,
  DatePicker,
  EmployeeSelect,
  ProductSelect,
  SublocationSelect,
} from '@ui/form';
import { FC, useState } from 'react';

export const InventoryAdjustmentAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  const [selectedBusinessLocation, setSelectedBusinessLocation] =
    useState<string>('');

  const handleSetSelectedBusinessLocation = (value: string) => {
    setSelectedBusinessLocation(value);
  };

  return (
    <>
      <ExpandableFormItem
        name={'location'}
        label={'Business Location'}
        defaultOpen={!!values?.location}
      >
        <BusinessLocationsSelect
          placeholder={'Choose location'}
          storesOnly={false}
          onChange={handleSetSelectedBusinessLocation}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Sublocation'}
        name={'sublocation'}
        defaultOpen={!!values?.sublocation}
        withDivider
      >
        <SublocationSelect
          placeholder={'Choose sublocation'}
          selectedStore={selectedBusinessLocation}
        />
      </ExpandableFormItem>
      <ExpandableFormItem
        name={'product'}
        label={'Product'}
        defaultOpen={!!values?.product}
        withDivider
      >
        <ProductSelect placeholder={'Choose product'} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Employee'}
        name={'employee'}
        defaultOpen={!!values?.employee}
        withDivider
      >
        <EmployeeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Adjustment Date'}
        name={'adjustmentDate'}
        defaultOpen={!!values?.adjustmentDate}
        withDivider
      >
        <DatePicker style={{ width: '100%', padding: '11px 15px' }} />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
