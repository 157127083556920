import { Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { CurrencyInput } from '@form/inputs/currencyInput/CurrencyInput';
import { CustomFormItem } from '@form/item/FormItem';

import { DealStages } from '@crm/profile/deals/types';
import { AllCustomersSelect, DatePicker, ProductSelect } from '@ui/form';
import capitalize from 'lodash/capitalize';
import { FC } from 'react';

interface Props {
  location: string;
}

const DealInputsList: FC<Props> = ({ location }) => {
  const dealStagesArray = Object.values(DealStages).map((value) => ({
    value,
    label: capitalize(value),
  }));

  return (
    <>
      <CustomFormItem name={'name'} label={'Deal Name'} required>
        <Input placeholder={'Enter text'} />
      </CustomFormItem>
      <CustomFormItem required label={'Start Date'} name={'startDate'}>
        <DatePicker style={{ width: '100%', padding: '11px 15px' }} />
      </CustomFormItem>
      <CustomFormItem required label={'Budget'} name={'budget'}>
        <CurrencyInput />
      </CustomFormItem>
      <CustomFormItem required label={'Stage'} name={'stage'}>
        <Select
          placeholder={'Select stage'}
          options={dealStagesArray}
          defaultActiveFirstOption
        />
      </CustomFormItem>
      {location === 'deal-management' && (
        <CustomFormItem label={'Contact'} name={'allContacts'}>
          <AllCustomersSelect />
        </CustomFormItem>
      )}
      <CustomFormItem label={'Products'} name={'products'}>
        <ProductSelect
          mode={'multiple'}
          placeholder={'Select products'}
          allowClear
        />
      </CustomFormItem>
      <CustomFormItem label={'Notes'} name={'notes'}>
        <TextArea />
      </CustomFormItem>
    </>
  );
};

export default DealInputsList;
