import { useLocalizationSettingsLazyQuery } from '@/graphql';
import { useEffect } from 'react';

type GetSettingsReturnType = {
  settingsLoading: boolean;
  userLocalizationInfo: LocalizationSettingsQuery | undefined;
};

export const useLocalizationSettings = (
  userId: string,
): GetSettingsReturnType => {
  const [fetchLocalizationSettings, { data: userLocalizationInfo, loading }] =
    useLocalizationSettingsLazyQuery();

  useEffect(() => {
    fetchLocalizationSettings({
      variables: {
        filters: {
          user: {
            id: {
              eq: userId,
            },
          },
        },
      },
    }).catch((error) => {
      console.error('Error fetching localization settings:', error?.message);
    });
  }, [userId, fetchLocalizationSettings]);

  return {
    userLocalizationInfo,
    settingsLoading: loading,
  };
};
