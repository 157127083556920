import dayjs from '@/dayjsConfig';
import { useLocalizationSettingsContext } from '@app/SettingsProvider';
import { replace } from 'lodash';

export const formatDateAndTime = (
  inputDate?: string | Maybe<Date> | number,
  withTime?: boolean,
  customDateFormat?: string | undefined,
): string => {
  const { dateFormat, timeFormat } = useLocalizationSettingsContext();

  const formattedDateFormat = replace(dateFormat ?? 'MM_DD_YYYY', /_/g, '/');
  const formattedTimeFormat = timeFormat === 'HH_mm' ? 'HH:mm' : 'hh:mm A';

  if (!inputDate) {
    return '-';
  }

  const dateTimeFormat = withTime
    ? `${customDateFormat ?? formattedDateFormat} ${formattedTimeFormat}`
    : customDateFormat ?? formattedDateFormat;

  return inputDate === 'nowDate'
    ? dayjs().tz().format(dateTimeFormat)
    : dayjs(inputDate).tz().format(dateTimeFormat);
};
