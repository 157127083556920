import { useMeQuery } from '@/graphql';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLocalizationSettings } from '@hooks/settings/useLocalizationSettings';
import { get } from 'lodash';
import dayjs from 'src/dayjsConfig';

interface SettingsContextProps {
  timeFormat: EnumLocalizationsettingTimeformat | undefined;
  setTimeFormat: React.Dispatch<
    React.SetStateAction<EnumLocalizationsettingTimeformat | undefined>
  >;
  dateFormat: EnumLocalizationsettingDateformat | undefined;
  setDateFormat: React.Dispatch<
    React.SetStateAction<EnumLocalizationsettingDateformat | undefined>
  >;
  timeZone: string;
  setTimeZone: React.Dispatch<React.SetStateAction<string>>;
}

const SettingsContext = createContext<SettingsContextProps | undefined>(
  undefined,
);

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: me } = useMeQuery();
  const userId = me?.me?.id ?? '';
  const { userLocalizationInfo } = useLocalizationSettings(userId);

  const initialDateFormat = get(
    userLocalizationInfo,
    'localizationSettings.data[0].attributes.dateFormat',
  );

  const initialEntityTimeZone = get(
    userLocalizationInfo,
    'localizationSettings.data[0].attributes.timeZone',
  );

  const initialEntityTimeFormat = get(
    userLocalizationInfo,
    'localizationSettings.data[0].attributes.timeFormat',
  );

  const [timeFormat, setTimeFormat] = useState<
    EnumLocalizationsettingTimeformat | undefined
  >(initialEntityTimeFormat);
  const [timeZone, setTimeZone] = useState(
    initialEntityTimeZone ?? initialTimeZone,
  );
  const [dateFormat, setDateFormat] = useState<
    EnumLocalizationsettingDateformat | undefined
  >(initialDateFormat);

  useEffect(() => {
    setTimeFormat(initialEntityTimeFormat);
    setTimeZone(initialEntityTimeZone ?? initialTimeZone);
    setDateFormat(initialDateFormat);
  }, [
    initialEntityTimeFormat,
    initialEntityTimeZone,
    initialDateFormat,
    initialTimeZone,
  ]);

  useEffect(() => {
    dayjs.tz.setDefault(timeZone);
  }, [timeZone]);

  return (
    <SettingsContext.Provider
      value={{
        dateFormat,
        setDateFormat,
        timeZone,
        setTimeZone,
        timeFormat,
        setTimeFormat,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useLocalizationSettingsContext = (): SettingsContextProps => {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error(
      'useLocalizationSettingsContext must be used within a SettingsProvider',
    );
  }

  return context;
};
