import { Dayjs } from 'dayjs';

import { FilterFunction } from '@filters/types';

export const createPriorityTypeFilter: FilterFunction<string> = (
  priority?: string,
): TaskFiltersInput => {
  return priority
    ? {
        priority: {
          eq: priority,
        },
      }
    : {};
};

export const createAssigneesFilter: FilterFunction<string[]> = (
  assignees?: string[],
): TaskFiltersInput => {
  return assignees?.length !== 0
    ? {
        assignees: {
          id: {
            in: assignees,
          },
        },
      }
    : {};
};

export const createTaskOrderFilter: FilterFunction<string> = (
  orderId?: string,
): TaskFiltersInput => {
  return orderId
    ? {
        order: {
          id: {
            eq: orderId,
          },
        },
      }
    : {};
};

export const createTasksDueDateFilter = (
  dueDate?: [Dayjs | null, Dayjs | null] | null,
): TaskFiltersInput => {
  if (!dueDate || (dueDate[0] === null && dueDate[1] === null)) {
    return {};
  }

  const startDate = dueDate[0]?.startOf('day').toDate();
  const endDate = dueDate[1]?.endOf('day').toDate();

  return {
    dueDate: {
      gte: startDate,
      lte: endDate,
    },
  };
};

export const createCustomersFilters = ({
  customer,
}: {
  customer?: {
    contact: string | null;
    lead: string | null;
    company: string | null;
  };
}) => {
  return customer
    ? {
        contact: {
          id: {
            eq: customer?.contact ?? undefined,
          },
        },
        company: {
          id: {
            eq: customer?.company ?? undefined,
          },
        },
        lead: {
          id: {
            eq: customer?.lead ?? undefined,
          },
        },
      }
    : {};
};

export const createApprovalMethodFilter: FilterFunction<string> = (
  approvalMethod?: string,
): TaskFiltersInput => {
  return approvalMethod
    ? {
        approvalMethods: {
          id: {
            eq: approvalMethod,
          },
        },
      }
    : {};
};

export const createForCompanyFilter: FilterFunction<string> = (
  forCompany?: string,
): TaskFiltersInput => {
  return forCompany
    ? {
        forCompanies: {
          id: {
            eq: forCompany,
          },
        },
      }
    : {};
};
export const createTaskTypeFilter: FilterFunction<string> = (
  taskType?: string,
): TaskFiltersInput => {
  return taskType
    ? {
        taskType: {
          id: {
            eq: taskType,
          },
        },
      }
    : {};
};

export const createTaskStageFilter: FilterFunction<string> = (
  taskStage?: string,
): TaskFiltersInput => {
  return taskStage
    ? {
        taskStage: {
          id: {
            eq: taskStage,
          },
        },
      }
    : {};
};

export const createTaskLocationFilter: FilterFunction<string> = (
  taskLocation?: string,
): TaskFiltersInput => {
  return taskLocation
    ? {
        taskLocation: {
          id: {
            eq: taskLocation,
          },
        },
      }
    : {};
};
